import { AbilityBuilder, Ability } from '@casl/ability';
import config from '../config/config';

export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  // Users can update their own info
  can('update', 'User', { id: user.id });


  // USER *************************
/*   can('disable', 'User');
  can('create', 'User');
  can('update', 'User');
  can('viewDataControllers', 'User')
  can('updateApiUserState', 'User')
  can('updateType', 'User')
 */
  switch(parseInt(user.user_type)){

    // ************* SUPPLIER ADMIN *************
    case 1 :
      // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******
      can('update', 'Company', { gln: user.InformationProviderGLN });
      cannot('update', 'Company', ['enrichmentPermissions']);

      can('addUser', 'Company', { gln: user.InformationProviderGLN });
      can('editUsers', 'Company', { gln: user.InformationProviderGLN });
      can('enrich', 'Company', { enrichmentPermissions: true });//Enrich button in Product Display

      // ******* USER *******
      can('update', 'User', { InformationProviderGLN: user.InformationProviderGLN });

      // ******* PREFIX *******
      
      // ******* QC *******

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******
      if(!user.isDataController) {
        can('update', 'Product', { InformationProviderGLN: user.InformationProviderGLN });
      } else {
        let ipglns = user.availableGLNs.map((gln, i) => {return gln.InformationProviderGLN});
        can('update', 'Product', { InformationProviderGLN: { $in: ipglns } });
      }

      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      cannot('view',"workList");
        cannot('view',"autoQc");
        cannot('view',"manualQc");

      //If the user belongs a company that hasn't EnrichmentPermission, the user cannot view Enrichments menu item.
      if(user.enrichmentPermissions === true) {
        can('view',"enrichments");
      }
      
      //Only for Preprod will be able to access to Digital link
      if(config.API_URL === 'https://apipreprod.trustedsource.co.za/') {
        can("view", "digitalLink");
      } else {
        cannot("view", "digitalLink");
      }

      cannot('view',"prefixManagement");
      cannot('view',"companyAndUserManagement");
      can('view',"companyManagement");
      can('view',"userManagement");
      cannot('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* DATA RECIPIENT ADMIN *************
    case 2 :
      // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******
      can('update', 'Company', { gln: user.InformationProviderGLN });
      cannot('update', 'Company', ['enrichmentPermissions']);

      can('addUser', 'Company', { gln: user.InformationProviderGLN });
      can('editUsers', 'Company', { gln: user.InformationProviderGLN });

      // ******* USER *******
      can('update', 'User', { InformationProviderGLN: user.InformationProviderGLN });

      // ******* PREFIX *******
      
      // ******* QC *******

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******
      can('contactSupplier')
      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      cannot('view',"workList");
        cannot('view',"autoQc");
        cannot('view',"manualQc");
      cannot('view',"enrichments");
      cannot('view',"prefixManagement");
      cannot('view',"companyAndUserManagement");
      can('view',"companyManagement");
      can('view',"userManagement");
      cannot('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* GS1 ADMIN *************
      case 3 : 
      // ******* DASHBOARD *******
      can('view','Dashboard');

      // ******* COMPANY *******
      can('changeState', 'Company');
      can('create', 'Company');
      can('update', 'Company');
      can('addUser', 'Company');
      can('editUsers', 'Company');
      can('disable', 'Company');
      
      // ******* USER *******
      can('changeState', 'User');
      can('create', 'User');
      can('update', 'User');

      // ******* PREFIX *******
      
      // ******* QC *******
      can('view', 'Qc');
      can('changeState', 'Qc');

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******     
      can('view', 'Product');
      can('contactSupplier')
      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      can('view',"workList");
      can('view',"autoQc");
      can('view',"manualQc");
      
      //Only for Preprod GS1 Admin will be able to access to Digital link
      if(config.API_URL === 'https://apipreprod.trustedsource.co.za/') {
        can("view", "digitalLink");
      } else {
        cannot("view", "digitalLink");
      }

      cannot('view',"enrichments");
      can('view',"prefixManagement");
      can('view',"companyAndUserManagement");
      cannot('view',"companyManagement");
      cannot('view',"userManagement");
      can('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* DATA RECIPIENT PUBLIC *************
      case 4 :
        // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******
      can('update', 'Company', { gln: user.InformationProviderGLN });
      cannot('update', 'Company', ['enrichmentPermissions']);

      // ******* USER *******
      can('update', 'User', { InformationProviderGLN: user.InformationProviderGLN });

      // ******* PREFIX *******
      
      // ******* QC *******

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******
      can('contactSupplier')
      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      cannot('view',"workList");
        cannot('view',"autoQc");
        cannot('view',"manualQc");
      cannot('view',"enrichments");
      cannot('view',"prefixManagement");
      cannot('view',"companyAndUserManagement");
      can('view',"companyManagement");
      can('view',"userManagement");
      cannot('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* SUPPLIER USER *************
    case 5 :
      // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******

      // ******* USER *******
      can('update', 'User', { InformationProviderGLN: user.InformationProviderGLN, id: user.id });
      // ******* PREFIX *******
      
      // ******* QC *******

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******
      can('update', 'Product', { InformationProviderGLN: user.InformationProviderGLN });

      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      cannot('view',"workList");
        cannot('view',"autoQc");
        cannot('view',"manualQc");
      cannot('view',"enrichments");
      cannot('view',"prefixManagement");
      cannot('view',"companyAndUserManagement");
      can('view',"companyManagement");
      can('view',"userManagement");
      cannot('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* DATA RECIPIENT USER *************
    case 6 :
      // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******
      

      // ******* USER *******
      can('update', 'User', { id: user.id });
      // ******* PREFIX *******
      
      
      // ******* QC *******
     

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******
      can('contactSupplier')
      // ******* ENRICHMENTS *******
      

      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      cannot('view',"workList");
        cannot('view',"autoQc");
        cannot('view',"manualQc");
      cannot('view',"enrichments");
      cannot('view',"prefixManagement");
      cannot('view',"companyAndUserManagement");
      can('view',"companyManagement");
      can('view',"userManagement");
      cannot('view',"messagesBoard");
      can('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        can('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");

      break;

    // ************* GS1 USER *************
    case 7 :
      // ******* DASHBOARD *******
      can('view', 'Dashboard');

      // ******* COMPANY *******

      // ******* USER *******

      // ******* PREFIX *******
      
      // ******* QC *******
      can('view', 'Qc');
      can('changeState', 'Qc');

      // ******* PRODUCT BROWSER *******
      can('view', 'ProductBrowser');

      // ******* PRODUCT *******

      // ******* MENU *******
      can('view','dashboard');
      can('view',"products");
      can('view',"workList");
        can('view',"autoQc");
        can('view',"manualQc");
      cannot('view',"enrichments");
      can('view',"prefixManagement");
      can('view',"companyAndUserManagement");
      cannot('view',"companyManagement");
      cannot('view',"userManagement");
      cannot('view',"messagesBoard");
      cannot('view',"reports");
        cannot('view',"companyManagementReport");
        cannot('view',"dataRecipientReport");
        cannot('view',"loginLogoutReport");
        cannot('view',"productReport");
        cannot('view',"qcManagementReport");
        cannot('view',"supplierReport");
        cannot('view',"syncListDataReport");
        cannot('view',"userManagementReport");
      can('view',"resources");
        can('view',"checkDigit");
        can('view',"gs1SaPortal");
        can('view',"searchGln");
        can('view',"verifiedByGs1");
      

      break;
  }

  return build();
}