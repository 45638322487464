import React, { useEffect ,useState }from "react";
import { Grid, TextField, Button, Typography, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Can } from "@casl/react";
import Header from "../Header/Header";
import SearchIcon from '@mui/icons-material/Search';
import QrCodeIcon from '@mui/icons-material/QrCode';
import toolsProductBrowserApi from "../../tools/toolsProductBrowserApi";
import useToken from '../App/useToken';
import useToolsNotifications from "../../tools/ToolsNotifications";
import toolsManagementApi from "../../tools/toolsManagementApi";
import config from "../../config/config";

export default function DigitalLink() {
	const userAbility = useSelector((state) => state.userAbility.value);
	const [url, setUrl] = useState(config.APP_PUBLIC_DOMAIN);
	const [gtin, setGtin] = useState('');
	const [expirationDate, setExpirationDate] = useState('yyyy/mm/dd');
	const [batch, setBatch] = useState('');
	const [serialNumber, setSerialNumber] = useState('');
	const [selectedGtin, setSelectedGtin] = useState('');
	const [selectedExpirationDate, setSelectedExpirationDate] = useState('');
	const [selectedBatch, setSelectedBatch] = useState('');
	const [selectedSerialNumber, setSelectedSerialNumber] = useState('');
	const [selectedUrl, setSelectedUrl] = useState(config.APP_PUBLIC_DOMAIN);
	const { token } = useToken();
	const { searchProducts } = toolsProductBrowserApi();
	const { generateQRCode } = toolsManagementApi();
	const { error, success } = useToolsNotifications();
	const [product, setProduct] = useState([]);
	const [QRPath, setQRPath] = useState(undefined);

	const handleSearch =  (() => {
		if(gtin !== undefined && gtin !== '') {
			let data = {
				count: "false",
				pageNumber: "0",
				pageSize: 1,
				search: {
					GTIN : gtin,
					OrderBy : "ts_tm_item.lastSyncDate/desc",
					brand : [],
					category : [],
					productType : "EA",
					status : [],
					sub_category : []
				}, 
				token: token,
				type: "BROWSER"
			}

	    	searchProducts(data).then(result => {
	            if(!result.error) {
	                if(result.data != undefined && result.data.products != undefined && Array.isArray(result.data.products) && result.data.products.length > 0) {
	                    setProduct(result.data.products[0]);
	                    setSelectedGtin(result.data.products[0].GTIN);
	                } else {
	                	setSelectedGtin('');
	                	error('GTIN not found.');
	                }
	            }
	        });
    	}
    });

	const handleGenerateQR = (() => {
		let data = {
			url: selectedUrl,
			gtin: selectedGtin,
			expirationDate: selectedExpirationDate,
			batch: selectedBatch,
			serialNumber: selectedSerialNumber,
			token: token
		}
		generateQRCode(data).then(result => {
        	if(result !== 'An error ocurred generating the QR code.') {
        		setQRPath(result);
        		success('Your QR Code was successfully generated.');
        	} else {
        		error(result);
        	}
        });
	});

	let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};
	let gridCellStyle = {padding:"5px 5px"};
	
	return 	<Grid height="100%">
                <Header name="Digital Link"/>
                {userAbility !== undefined ?
                    <Can I="view" this="digitalLink" ability={userAbility}>
                    	<Grid container xs={12} >
	                        <Grid container xs={6} sx={{padding: '41px 44px', boxShadow: 'rgba(30, 177, 224, 0.1) 6px 6px 10px'}} >
	                        	<Typography variant="userInfoValue">Search the GTIN to generate your QR code</Typography>
	                       		<Grid container item style={gridCellStyle}>
	                       			<TextField 
			                            label="Search GTIN"
			                            variant="outlined" 
			                            style={inputText}
			                            value={gtin}
			                            onChange={(e) => {setGtin(e.target.value)}}
			                            required
			                        />
	                       		</Grid>
	                       		<Grid container item style={gridCellStyle} justifyContent="center" alignContent="center">
	                       			<Button variant="orange-nosize" style={inputText} onClick={handleSearch} startIcon={<SearchIcon/>} > Search </Button>
	                       		</Grid>
	                       		<Typography variant="userInfoValue" paddingTop="40px">Complete data to generate your QR code</Typography>
	                       		<Grid container item style={gridCellStyle}>
	                       			<TextField 
			                            label="URL"
			                            variant="outlined" 
			                            style={inputText}
			                            value={url}
			                            onChange={(e) => {setUrl(e.target.value);setSelectedUrl(e.target.value)}}
			                            required
			                        />
	                       		</Grid>
	                       		<Grid container item style={gridCellStyle}>
	                       			<TextField 
			                            label="Expiration Date"
			                            variant="outlined" 
			                            style={inputText}
			                            value={expirationDate}
			                            onChange={(e) => {setExpirationDate(e.target.value);setSelectedExpirationDate(e.target.value)}}
			                            type="date"
			                            
			                        />
	                       		</Grid>
	                       		<Grid container item style={gridCellStyle}>
	                       			<TextField 
			                            label="Batch"
			                            variant="outlined" 
			                            style={inputText}
			                            value={batch}
			                            onChange={(e) => {setBatch(e.target.value);setSelectedBatch(e.target.value)}}
			                        />
	                       		</Grid>
	                       		<Grid container item style={gridCellStyle}>
	                       			<TextField 
			                            label="Serial Number"
			                            variant="outlined" 
			                            style={inputText}
			                            value={serialNumber}
			                            onChange={(e) => {setSerialNumber(e.target.value);setSelectedSerialNumber(e.target.value)}}
			                        />
	                       		</Grid>
	                       		<Grid container item style={gridCellStyle} >
	                       			<Button variant="orange-secondary-nosize" style={inputText} startIcon={<QrCodeIcon/>} onClick={handleGenerateQR} disabled={selectedGtin !== undefined && selectedGtin !== '' && selectedUrl !== undefined && selectedUrl !== ''?false:true} >Generate QR</Button>
	                       		</Grid>
	                        </Grid>
	                       
                        </Grid>
                        {QRPath !== undefined ?
	                        <Grid container item xs={6} justifyContent="center" sx={{padding: '20px 44px', boxShadow: 'rgba(30, 177, 224, 0.1) 6px 6px 10px'}} >
	                    		<Box sx={{textAlign: "center"}}>
	                    			<Typography variant="PDTitle" component="p">Your QR Code</Typography>
	                    			<Box component="img" src={config.API_URL +'public/images/'+ QRPath} width="250px" height="250px"></Box>
	                    		</Box>
		                    </Grid>
	                    : ''}
                    </Can>
                :
                    ''}
            </Grid>;
}